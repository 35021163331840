/** @jsx jsx */
import { memo, ReactElement } from "react";
import { jsx, Text } from "theme-ui";
import Layout from "../components/Layout";

const NotFoundPage = memo(function NotFoundPage(): ReactElement {
  return (
    <Layout title="Not Found">
      <Text sx={{ fontSize: 5 }}>404 Not Found</Text>
    </Layout>
  );
});
export default NotFoundPage;
